<template>
  <en-table-layout :tableData="marketList.data" @selection-change="handleSelectionChange2">
    <template slot="toolbar">
      <div class="col-auto toolbar-title">
        充值分发详情
      </div>

      <el-form-item label="短信回执状态">
        <el-select size="medium" class="choose-machine" v-model="inform.send_status" placeholder="请选择"
          @change="changeInit" clearable>
          <el-option v-for="item in shopTypeList" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="手机号">
        <el-input v-model="inform.mobile" placeholder="请输入手机号" clearable></el-input>
      </el-form-item>

      <el-button @click="searchEvent" type="primary" size="small">
        搜索
      </el-button>
      <div class="buttonbc" style="position: absolute;right:0">
        <el-button @click="() => { $router.go(-1); }" size="small" class="back_btn">
          <img src="@/assets/back.png" alt="">
        </el-button>
      </div>
    </template>

    <template slot="table-columns">
      <el-table-column label="序号" type="index" width="55"></el-table-column>
      <el-table-column label="用户ID" width="130">
        <template slot-scope="scope">{{ scope.row.member_id }}</template>
      </el-table-column>
      <el-table-column label="手机号" width="200">
        <template slot-scope="scope">{{ scope.row.mobile }}</template>
      </el-table-column>
      <el-table-column label="分发金额值" width="150">
        <template slot-scope="scope">{{ scope.row.charge_money }}</template>
      </el-table-column>
      <el-table-column label="短信回执" min-width="280">
        <template slot-scope="scope">
          {{ getSendStatus(scope.row.send_status) }}
        </template>
      </el-table-column>
      <el-table-column label="操作" width="200">
        <template slot-scope="scope">
          <el-button size="mini" type="primary" @click="deleteMsg(scope.row)">
            删除
          </el-button>
          <el-button size="mini" v-if="scope.row.send_status === 1 || scope.row.send_status === 2" type="primary"
            @click="seeMeal(scope.row)">
            重新发送
          </el-button>
        </template>
      </el-table-column>
    </template>

    <el-pagination slot="pagination" @size-change="handleSizeChange" @current-change="handleCurrentChange"
      :current-page="inform.page_no" :page-size="inform.page_size" :page-sizes="MixinPageSizes" :layout="MixinTableLayout"
      background :total="total"></el-pagination>
  </en-table-layout>
</template>

<script>
import * as API_Users from '@/api/users';
import EnTableLayout from '../../../ui-components/TableLayout/src/main';

export default {
  components: { EnTableLayout },
  data () {
    return {
      marketList: [],
      currentPage4: 4,
      total: 0,
      inform: {
        mobile: '',
        send_status: '',
        page_no: 1,
        page_size: 20,

      },
      shopTypeList: [
        // { label: "不用发送", value: 0 },
        // { label: "待成功", value: 1 },
        {
          label: '发送失败',
          value: 2
        },
        {
          label: '发送成功',
          value: 3
        }
      ]
    };
  },
  mounted () {
    this.inform.charge_id = this.$route.query.charge_id;
    this.getList();
  },
  methods: {
    getSendStatus (status) {
      switch (status) {
        case 0:
          return '不用发送';
        case 1:
          return '待发送';
        case 2:
          return '发送失败';
        case 3:
          return '发送成功';
      }
    },
    deleteMsg (value) {
      this.$confirm('确定删除此条数据？', '确认信息', {
        distinguishCancelAndClose: false,
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      })
        .then(() => {
          API_Users.deleteetail(value.id).then(_ => {
            this.$message.success('删除成功');
            this.getList();
          });
        })
        .catch(_ => {
        });
    },
    searchEvent () {
      this.getList();
    },
    changeInit () {
    },
    getList () {
      API_Users.getDetail(this.inform).then(res => {
        this.marketList.data = res.data;
        this.marketList = res;
        this.total = res.data_total;
      });
    },
    handleSelectionChange2 (val) {
      this.marketGoods = val;
    },
    handleSizeChange (val) {
      this.inform.page_size = val;
      this.getList();
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange (val) {
      this.currentPage4 = val;
      this.inform.page_no = val;
      this.getList();
      console.log(`当前页: ${val}`);
    },
    seeMeal (val) {
      API_Users.sendMessage(val.id).then(res => {
        console.log(res, '操作成功');
        this.getList();
      });
    }
  }
};
</script>

<style scoped lang="scss">
.chongmsg {
  position: relative;
  padding: 10px 20px;
  background-color: #fff;
  border-radius: 5px;
}

.high {
  display: inline-block;
  margin: 5px 0 0 0;
}

.ffg>div {
  margin-bottom: 10px;
}

.ffg div * {
  vertical-align: middle;
}

.ffg div lebel {
  display: inline-block;
  width: 65px;
  text-align: right;
}
</style>
